import React, { useState, useEffect } from "react";

const MealList = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("https://api.keitolle.com/meal")
      .then(res => res.json())
      .then(
        items => {
          setIsLoaded(true);
          setItems(items);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <ul>
      {items.map(item => (
        <li key={item.name}>
          {item.name} {item.price}
        </li>
      ))}
    </ul>
  );
};

export default MealList;
